<template>
   <b-nav-item-dropdown right class="notification-list" menu-class="dropdown-lg">
      <template slot="button-content">
        <span class="nav-link  dropdown-toggle">
          <i class="fe-bell noti-icon"></i>
          <span v-if="unreadNotifications.length" class="badge badge-danger rounded-circle noti-icon-badge">{{unreadNotifications.length}}</span>
        </span>
      </template>
      <a href="javascript:void(0);" class="dropdown-item noti-title">
          <h5 class="m-0">
              <span class="float-right">
              </span>
              Notifications
          </h5>
      </a>

      <notification-list
       v-if="displayNotifs && displayNotifs.length"
       :list="displayNotifs"
       />

      <a
        href="javascript:void(0);"
        class="dropdown-item text-center text-primary notify-item notify-all"
        @click.prevent="$router.push('/notifications').catch(() => {})">
          View all
          <i class="fi-arrow-right"></i>
      </a>
  </b-nav-item-dropdown>
</template>

<script>
import NotificationList from "./NotificationList.vue"
export default {
  components:{
      NotificationList
  },
  data() {
    return {
    }
  },
  computed: {
    unreadNotifications() {
      return this.$store.state.notification.notifications.filter((notif) => (notif.is_seen == 0))
    },
    displayNotifs(){
      return (this.unreadNotifications.length > 6)
        ? this.unreadNotifications
        : this.$store.state.notification.notifications.slice(0,10)
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    }
  },
  created(){
    this.$store.dispatch("notification/fetchNotifications")
  },
  methods:{
    actionExecutor(data){
      if(data.action == "fetch-order"){
        let order = this.$store.state.orderList.order;
        if(order && order.id == data.order_id){
          this.$store.dispatch("orderList/fetchOrder", data.order_id)
        }
      }
      if(data.action == "fetch-orders"){
        this.$store.dispatch("orderList/fetchOrders")
      }
    }
  },
  mounted(){
    // Echo.channel("admin-notify-channel")
    //   .listen('NotificationEvent', (e) => {
    //     this.$store.commit('notification/ADD_NOTIFICATION',e.data)
    //     this.actionExecutor(e.data)
    // });
  }
}

</script>

<style>
.notification-list .dropdown-toggle{
    position: relative;
}
</style>
